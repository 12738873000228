import request from "@/plugins/axiosRequest";

const url = "/v1/channel_messages";

export function chatHistories() {
    return request({
        url: url,
        method: "get"
    });
}


export function sayMessage(message) {
    let data = {
        message,
        channel: "english"
    }
    return request({
        url: url,
        method: "post",
        data
    });
}

export default {chatHistories, sayMessage};
