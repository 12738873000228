import {
    loginByEmail,
    validateToken,
    updatePassword,
    updateProfile,
    requestOtt,
    sendResetPassword,
    resetPassword
} from "@/api/session";
import {getToken} from "@/utils/auth";

const profile = {
    state: {
        profile: {
            name: "Player",
            email: "",
            total_points: 0,
            total_tokens: 0,
            wallet_address: null,
            affiliate_type: 'player'
        },
        ottToken: null
    },
    mutations: {
        SET_PROFILE: (state, profile) => {
            state.profile = profile;
        },
        SET_OTT_TOKEN: (state, token) => {
            state.ottToken = token;
        }
    },

    actions: {
        LoginByEmail({commit}, userInfo) {
            const email = userInfo.email.trim();
            return new Promise((resolve, reject) => {
                loginByEmail(email, userInfo.password)
                    .then(response => {
                        const data = response.data;
                        commit("SET_PROFILE", data["data"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchProfile({commit}) {
            return new Promise((resolve, reject) => {
                let token = getToken();
                if (token) {
                    validateToken()
                        .then(response => {
                            const data = response.data;
                            if (data && data["success"]) {
                                commit("SET_PROFILE", data["data"]);
                                commit("UPDATE_BALANCE", parseInt(data["data"]["total_points"]));
                                commit("UPDATE_WINNING_POINTS", parseInt(data["data"]["winning_points"]));
                                commit("UPDATE_TOKEN_BALANCE", parseFloat(data["data"]["total_tokens"]));
                                resolve();
                            } else {
                                reject(response);
                            }
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    reject();
                }
            });
        },
        updatePassword(
            {commit},
            {currentPassword, password, passwordConfirmation}
        ) {
            return new Promise((resolve, reject) => {
                updatePassword(currentPassword, password, passwordConfirmation)
                    .then(response => {
                        const data = response.data;
                        commit("UPDATE_BALANCE", parseInt(data["data"]["total_points"]));
                        commit("UPDATE_WINNING_POINTS", parseInt(data["data"]["winning_points"]));
                        commit("UPDATE_TOKEN_BALANCE", parseFloat(data["data"]["total_tokens"]));
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        updateProfile({commit}, data) {
            return new Promise((resolve, reject) => {
                updateProfile(data)
                    .then(response => {
                        const data = response.data;
                        commit("SET_PROFILE", data["data"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        logout({commit}) {
            commit("SET_PROFILE", {
                name: "Player",
                email: "",
                total_points: 0,
                total_tokens: 0
            });
        },
        requestOtt({commit}) {
            return new Promise((resolve, reject) => {
                console.log("Request OTT")
                if (location.pathname.includes("/reset-password")) {
                    reject("Reset password");
                }
                let token = getToken();
                if (token) {
                    requestOtt()
                        .then(response => {
                            const data = response.data;
                            commit("SET_OTT_TOKEN", data);
                            resolve(data);
                        })
                        .catch(error => {
                            reject(error);
                        });
                } else {
                    reject("Not token");
                }
            });
        },
        // eslint-disable-next-line no-unused-vars
        sendResetPassword({commit}, userInfo) {
            const email = userInfo.email.trim();
            return new Promise((resolve, reject) => {
                sendResetPassword(email, userInfo.redirectUrl)
                    .then(response => {
                        const data = response.data;
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        // eslint-disable-next-line no-unused-vars
        resetPassword({commit}, data) {
            return new Promise((resolve, reject) => {
                resetPassword(data)
                    .then(response => {
                        const data = response.data;
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    getters: {
        currentUser: state => state.profile
    }
};

export default profile;
