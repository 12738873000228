const TokenKey = "UserToken";

export function getToken() {
    let data = localStorage.getItem(TokenKey);
    let token = null;
    if (data) {
        token = JSON.parse(data);
    }
    return token;
}

export function setToken(token) {
    return localStorage.setItem(TokenKey, JSON.stringify(token));
}

export function removeToken() {
    return localStorage.removeItem(TokenKey);
}

export function checkInvitationCode() {
    let params = new URLSearchParams(window.location.search)
    let referralCode = params.get("invited_by")
    if (referralCode) {
        localStorage.setItem("REFERRAL_CODE", referralCode)
    } else {
        let cookieValue = document.cookie
            .split('; ')
            .find(row => row.startsWith('REFERRAL_CODE='))
        if (cookieValue) {
            referralCode = cookieValue.split('=')[1];
            localStorage.setItem("REFERRAL_CODE", referralCode)
        }
    }
    return !!referralCode
}
