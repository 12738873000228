import {checkInvitationCode, removeToken} from "@/utils/auth";
import {logout} from "@/api/session";

const whiteList = [
    "/sign-up",
    "/login",
    "/forgot-password",
    "/reset-password",
    "/404",
    "/403",
    "/500"
];

export default [
    {
        name: "APP_LOGIN_SUCCESS",
        callback: function () {
            this.$router.replace({path: "/"});
        }
    },
    {
        name: "APP_LOGOUT",
        callback: function () {
            logout()
                .then(() => {
                    removeToken();
                    this.$router.replace({path: "/login"});
                    location.reload()
                })
                .catch(error => {
                    console.log(error);
                    removeToken();
                    this.$router.replace({path: "/login"});
                    location.reload()
                });
        }
    },
    {
        name: "APP_AUTH_FAILED",
        callback: function () {
            removeToken();
            if (whiteList.indexOf(this.$route.path) === -1) {
                let check = checkInvitationCode()
                if (check) {
                    this.$router.replace({path: "/sign-up"});
                } else {
                    this.$router.replace({path: "/login"});
                }
            }
        }
    }
];
