import Vue from "vue";
import Vuex from "vuex";
import session from "./session";
import exchangeRates from "./exchangeRates";
import referrals from "./referrals";
import gameSession from "./gameSession";
import settings from "./settings";
import chatEngine from "./chat";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        session,
        exchangeRates,
        referrals,
        gameSession,
        settings,
        chatEngine
    },
    state: {
        collapseNav: false
    },
    getters: {},
    mutations: {},
    actions: {}
});
