import {getExchangeRates, coinExchangeRates} from "@/api/exchangeRates";

const exchangeRates = {
    state: {
        rates: {},
        prices: {}
    },
    mutations: {
        SET_EXCHANGE_RATES: (state, data) => {
            state.rates = data;
        },
        SET_COIN_PRICE: (state, data) => {
            state.prices["BTC"] = data
        },
        SET_USDT_PRICE: (state, data) => {
            state.prices["USDT"] = data
        }
    },
    actions: {
        fetchExchangeRates({commit}) {
            return new Promise((resolve, reject) => {
                getExchangeRates()
                    .then(response => {
                        const data = response.data;
                        commit("SET_EXCHANGE_RATES", data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        fetchCoinExchangeRates({commit}) {
            return new Promise((resolve, reject) => {
                coinExchangeRates("USDT", "USD")
                    .then(response => {
                        const data = response.data;
                        commit("SET_USDT_PRICE", data["rate"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
                coinExchangeRates("BTC", "USD")
                    .then(response => {
                        const data = response.data;
                        commit("SET_COIN_PRICE", data["rate"]);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    },
    getters: {
        exchangeRates: state => state.rates,
        coinExchangeRates: state => state.prices
    }
}

export default exchangeRates;
