import request from "@/plugins/axiosRequest";

const playUrl = "/v1/game_slots";
const gamesUrl = "/v1/game_session";

export function listGameHistories(queries) {
    return request({
        url: gamesUrl,
        method: "get",
        params: queries
    });
}

// bet_amount, bet_ratio
export function playCurrentGame(data) {
    return request({
        url: playUrl,
        method: "post",
        data
    });
}

export function gameHistories(queries) {
    return request({
        url: playUrl,
        method: "get",
        params: queries
    });
}

export default {listGameHistories, playCurrentGame, gameHistories};
