import Vue from 'vue'
import Router from 'vue-router'
import {getToken} from "@/utils/auth";
import {checkInvitationCode} from "@/utils/auth";

Vue.use(Router)

const whiteList = [
    "/reset-password",
    "/forgot-password",
    "/login",
    "/sign-up"
];

const router = new Router({
    mode: 'history',
    routes: [
        {
            name: 'Home', path: '/', component: () => import('../views/dashboardPage.vue')
        },
        {
            name: 'My fund', path: '/funds', component: () => import('../views/fundPage.vue')
        },
        {
            name: 'My affiliate', path: '/affiliate', component: () => import('../views/affiliatePage.vue')
        },
        {
            name: 'Game history', path: '/histories', component: () => import('../views/gameHistoryPage.vue')
        },
        {
            name: 'Settings', path: '/settings', component: () => import('../views/settingPage.vue')
        },
        {
            name: 'ResetPassword', path: '/reset-password', component: () => import('../views/ResetPassword.vue')
        },
        {
            name: 'SignIn', path: '/login', component: () => import('../views/SignInPage.vue')
        },
        {
            name: 'ForgotPassword', path: '/forgot-password', component: () => import('../views/forgotPasswordPage.vue')
        },
        {
            name: 'SignUp', path: '/sign-up', component: () => import('../views/SignUpPage.vue')
        },
        {
            name: 'ContactSupport', path: '/contact-support', component: () => import('../views/contactSupportPage.vue')
        },
        {
            path: '*', redirect: '/'
        }
    ]
})

// router guards
router.beforeEach((to, from, next) => {
    if (getToken()) {
        next();
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            let check = checkInvitationCode()
            if (check) {
                next(`/sign-up`);
            } else {
                next(`/login`);
            }
        }
    }
});
export default router;
