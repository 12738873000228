<template>
  <el-dialog class="modal-common "
             :title="$t('menu.contactSupport')"
             :visible="visible"
             width="40%"
             :before-close="hide"
             :close-on-click-modal="false"
             :closeOnPressEscape="false">
    <div class="body-bg-modal">
      <div class="row">
        <div class="col-12">
          <div class="row">
            <div class="col-pane-title col-lg-3 col-md-3 col-12">
              <label class="z_tmrc_cft031" for="subject">{{ $t("common.subject") }}</label>
            </div>
            <div class="col-pane-title col-lg-9 col-md-9 col-12">
              <input type="text"
                     class="form-control-plaintext"
                     id="subject"
                     v-model="formData.subject"/>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-pane-title col-lg-3 col-md-3 col-12">
              <label class="z_tmrc_cft031" for="subject">{{ $t("common.inquiry") }}</label>
            </div>
            <div class="col-pane-title col-lg-9 col-md-9 col-12">
                            <textarea class="form-control-plaintext" name="" id="" cols="30" rows="10"
                                      v-model="formData.inquiry"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <el-alert class="mb-4" v-if="showError"
                        :title="errorMessage"
                        :type="typeError"
                        :closable="false">
              </el-alert>
              <div class="text-center mt-4">
                <button type="button"
                        class="btn btn-primary-common"
                        @click="sendRequest">
                  {{ $t("button.send") }}
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </el-dialog>
</template>

<script>
import API from "@/api"

export default {
  name: "contactSupportPage",
  components: {},
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      formData: {
        subject: "",
        inquiry: ""
      },
      showError: false,
      errorMessage: "",
      typeError: ""
    };
  },
  methods: {
    hide() {
      this.$emit("update:visible", false);
      this.showError = false;
      this.errorMessage = null;
    },
    sendRequest() {
      if (this.loading) {
        return
      }
      this.loading = true;
      API.sendConsultations(this.formData)
          .then(() => {
            this.loading = false;
            let message = this.$t("messages.sendSupport");
            //this.$message.success(message)
            //this.hide()
            this.showError = true;
            this.errorMessage = message;
            this.typeError = "success";
            this.formData.subject = "";
            this.formData.inquiry = "";
          })
          .catch((error) => {
            this.loading = false;
            this.showError = true;
            this.errorMessage = error;
            this.typeError = "error";
            console.error(error)
          });
    }
  },
  watch: {},
};
</script>
