import * as Session from "@/api/session";
import Consultations from "@/api/consultations"
import ExchangeRates from "@/api/exchangeRates"
import Transactions from "@/api/transactions"
import Referrals from "@/api/referrals"
import Game from "@/api/game"

export default {
    session: Session,
    sendConsultations: Consultations.sendConsultations,
    getExchangeRates: ExchangeRates.getExchangeRates,
    getTransactions: Transactions.getTransactions,
    sendWithdrawnRequest: Transactions.sendWithdrawnRequest,
    sendDepositRequest: Transactions.sendDepositRequest,
    getDepositRequest: Transactions.getDepositRequest,
    getReferrals: Referrals.getReferrals,
    getReferralsOverview: Referrals.getReferralsOverview,
    gameHistories: Game.gameHistories,
    playCurrentGame: Game.playCurrentGame,
    listGameHistories: Game.listGameHistories,
};
