import {fetchList} from "@/api/systemSettings";

const app = {
    state: {
        //language: localStorage.getItem("language") || "en",
        language: navigator.language.split('-')[0] || "en",
        systemSettings: {
            "TRC_BONUS_PERCENT": 0,
            "MIN_POINT_WITHDRAWAL_FEE": 300,
            "WITHDRAWAL_FEE_RATIO": 1
        }
    },
    mutations: {
        SET_LANGUAGE: (state, language) => {
            state.language = language;
            localStorage.setItem("language", language);
        },
        SET_SYSTEM_SETTINGS: (state, systemSettings) => {
            state.systemSettings = systemSettings;
        }
    },
    actions: {
        setLanguage({commit}, language) {
            commit("SET_LANGUAGE", language);
        },
        getSystemSettings({commit}) {
            fetchList().then(response => {
                commit("SET_SYSTEM_SETTINGS", response.data);
            }).catch(error => {
                console.error(error)
            })
        }
    },
    getters: {
        language: state => state.language,
        systemSettings: state => state.systemSettings
    }
};

export default app;
