import request from "@/plugins/axiosRequest";

const url = "/v1/transactions";
const urlWithdrawn = "/v1/withdrawn_requests";
const urlDeposit = "/v1/deposit_requests";

export function getTransactions(queries) {
    return request({
        url: url,
        method: "get",
        params: queries
    });
}

// {
//     "requested_point": 10000,
//     "currency": "BTC",
//     "tx_wallet_address": "3B77V3yjySkJqr5QsYAyhS5jMoAShUhyde"
//     "note": "Withdrawn"
// }
export function sendWithdrawnRequest(data) {
    return request({
        url: urlWithdrawn,
        method: "post",
        data
    });
}

export function sendDepositRequest(data) {
    return request({
        url: urlDeposit,
        method: "post",
        data
    });
}

export function getDepositRequest(requestID) {
    return request({
        url: urlDeposit + "/" + requestID,
        method: "get",
    });
}

export default {getTransactions, sendWithdrawnRequest, sendDepositRequest, getDepositRequest};
