<template>
  <div :class="{ toggled: $store.state.collapseNav }" class="sidebar d-flex flex-column flex-shrink-0 p-0">
    <div class="container-fluid d-flex flex-column p-0">
      <div class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0">
        <div class="sidebar-brand-icon">
          <div><img src="@/assets/img/2_tmra_0001_03_logo_y1x4_w.svg" style="width: 30px;"></div>
        </div>
        
        <div class="sidebar-brand-text mx-3">
          <div><img class="w-100" src="@/assets/img/2_tmra_0001_04_logo_y1x4_w.svg" width="130" height="14"></div>
        </div>
      </div>
      <ul class="navbar-nav text-light mb-4">
        <li class="nav-item">
          <router-link to="/" tag="a" class="nav-link">
            <i class="fa-solid fa-gamepad fs-4 mx-2 "></i>
            <span>{{ $t('menu.playGame') }}</span>
          </router-link>

        </li>
        <li class="nav-item">
          <router-link to="/funds" tag="a" class="nav-link">
            <i class="fa-solid fa-money-bill-1 fs-4 mx-2 "></i>
            <span>{{ $t('menu.myFund') }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/affiliate" tag="a" class="nav-link" active-class="active">
            <i class="fa-solid fa-share-alt fs-4 mx-2 "></i>
            <span>{{ $t('menu.myAffiliate') }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/histories" tag="a" class="nav-link">
            <i class="fa-solid fa-history fs-4 mx-2 "></i>
            <span>{{ $t('menu.gameHistories') }}</span>
          </router-link>
        </li>

        <li class="nav-item pb-4"></li>
        <!-- <li class="nav-item">
          <a class="nav-link" href="https://albosdiag703.blob.core.windows.net/timerich-avatar/Manual_for_Time rich.pdf"
            target="_blank">
            <i class="fa-solid fa-book fs-4 mx-2 "></i>
            <span>{{ $t('menu.userGuide') }}</span>
          </a>

        </li> -->
        <li class="nav-item">
          <router-link to="/contact-support" tag="a" class="nav-link">
            <i class="fa-solid fa-history fs-4 mx-2 "></i>
            <span>{{ $t('menu.contactSupport') }}</span>
          </router-link>
        </li>

        <li class="nav-item">
          <router-link to="/settings" tag="a" class="nav-link">
            <i class="fa-solid fa-gear fs-4 mx-2 "></i>

            <span>{{ $t("headerMenu.setting") }} </span>
          </router-link>
        </li>
        <li class="nav-item pb-4"></li>
        <li class="nav-item">
          <a class="nav-link " @click="logOut">
            <i class="fa-solid fa-sign-out-alt fs-4 mx-2 "></i>
            <span>{{ $t("headerMenu.logout") }}</span></a>
        </li>


      </ul>
      <div class="text-center d-none d-md-inline">
        <button @click="handleNAvStatus" class="btn rounded-circle border-0 btn-collapse-nav" id="sidebarToggle" type="button">
          <i class="fas fa-angle-right" v-if="this.$store.state.collapseNav"></i>
          <i class="fas fa-angle-left" v-else></i>
        </button>
      </div>
      <!-- <hr> -->
      <!-- <div class="text-center">
        <el-button circle icon="el-icon-arrow-left" @click="onToggleNav">
        </el-button>
      </div> -->
      <!-- <nav v-if="$store.state.collapseNav" :class="{ 'toggled': isToggleNav }"
        class="navbar navbar-dark  align-items-start z_tmrc_cbg024 sidebar sidebar-dark accordion p-0"
        style="width: 280px;">
        <div class="container-fluid d-flex flex-column p-0">

          <div class="text-center d-none d-md-inline"><button @click="onToggleNav" class="btn rounded-circle border-0"
              id="sidebarToggle" type="button"></button></div>
        </div>
      </nav> -->
    </div>
    <contact-support-page :visible.sync="dialogContactSupportVisible"></contact-support-page>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import UserGuidePage from "@/modules/userGuidePage";
import ContactSupportPage from "@/modules/contactSupportPage";

export default {
  data() {
    return {
      dialogSettingVisible: false,
      dialogSignInVisible: false,
      dialogCreateAccountVisible: false,
      dialogUserGuideVisible: false,
      dialogContactSupportVisible: false,
      isTesting: process.env.VUE_APP_TESTNET === 'true',
    };
  },
  components: {
    // UserGuidePage,
    ContactSupportPage,

  },
  computed: {
    ...mapGetters(["currentUser"]),
    userName() {
      if (this.currentUser.id) {
        if (!this.currentUser.name && !this.currentUser.last_name) {
          return this.currentUser.email;
        } else {
          return (this.currentUser.name || "") + " " + (this.currentUser.last_name || "");
        }
      }
      return "Player";
    },
  },
  methods: {
    logOut() {
      this.$store.dispatch("logout")
      this.$store.state.collapseNav = false;
      window.getApp.$emit("APP_LOGOUT");
    },
    signInFn() {
      this.dialogSignInVisible = true;
    },
    showMenu() {
      this.$store.state.collapseNav = true;
    },
    onSignIn() {
      this.$emit("update:visible", false);
      this.$store.state.collapseNav = true;
      // this.$emit("showNav", this.showNav);
    },
    handleNAvStatus() {
      this.$store.state.collapseNav = !this.$store.state.collapseNav;
    }
  },

  created() {
    this.$store.dispatch("fetchProfile").then(() => {
      // console.log(this.currentUser)
      if (this.currentUser.id) {
        this.onSignIn()
      }
    }).catch(() => {
    });
  },
  filters: {
    firstCharacter: function (value, onlyFirstCharacter) {
      if (!value) {
        return '';
      }

      value = value.toString();

      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase();
      } else {
        return value.toUpperCase();
      }
    }
  }

};
</script>
<style scoped lang="scss">
.sidebar {
  width: 280px;
  background: #1e2454;
  z-index: 2;

  .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 800;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05rem;
    z-index: 1;
  }

  .nav-link {
    color: #ffffff;

    &:focus,
    &:hover {
      color: rgb(7, 225, 235) !important;

    }
  }
}

.sidebar {
  width: 6.5rem;
  min-height: 100vh;
  position: fixed;
  .nav-item .nav-link {
    text-align: center;
    padding: 0.75rem 1rem;
    width: 6.5rem;
    font-size: .65rem;
    color: rgba(255, 255, 255, .8);

    &.active,
    &.router-link-exact-active {
      color: rgb(7, 225, 235) !important;
      font-weight: bold !important;

      i {
        color: #ffffff;
      }
    }

    span {
      display: block;
    }

    i {
      color: rgba(255, 255, 255, .3);
    }
  }

  .sidebar-brand .sidebar-brand-text {
    display: none;
  }

  &.toggled {
    width: 0 !important;
    overflow: hidden;
  }
}


@media (min-width: 768px) {
  .sidebar {
    width: 14rem !important;
    
    .nav-item .nav-link {
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      padding: 1rem;
      width: 14rem;
      font-size: .85rem;

      span {
        display: inline;
      }
    }

    &.toggled {
      overflow: visible;
      width: 6.5rem !important;

      .nav-item .nav-link {
        display: block;
        text-align: center;
        padding: 0.75rem 1rem;
        width: 6.5rem;
        font-size: .65rem;

        span {
          display: block;
        }
      }
      .sidebar-brand .sidebar-brand-text {
    display: none;
}
    }
  }

  .sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
  }
}

.btn-collapse-nav{
  width: 2.5rem;
    height: 2.5rem;
    text-align: center;
    margin-bottom: 1rem;
    cursor: pointer;
    background-color: rgba(255,255,255,.2);
    &:hover{
      background-color: rgba(255,255,255,.25);
    }
}
</style>
