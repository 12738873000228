// import {listCurrentPlayers} from "@/api/game";

let localData = localStorage.getItem("AUTO_PLAY_SETTINGS")
if (localData) {
    localData = JSON.parse(localData)
    if (localData.playSound === undefined) {
        localData.playSound = true
        localStorage.setItem("AUTO_PLAY_SETTINGS", JSON.stringify(localData))
    }
} else {
    localData = {
        betAmount: null,
        betRatio: null,
        playTimes: 100,
        playSound: true
    }
    localStorage.setItem("AUTO_PLAY_SETTINGS", JSON.stringify(localData))
}

const gameData = {
    state: {
        isConnected: false,
        username: null,
        currentPoint: 0,
        winningPoints: 0,
        currentTokens: 0,
        game: {
            gameState: null,
            created: null,
            cashingOut: false,
            /**
             * Client side times:
             * if the game is pending, startTime is how long till it starts
             * if the game is running, startTime is how long its running for
             * if the game is ended, startTime is how long since the game started
             */
            startTime: null,
            state: "starting",
            placingBet: false,
            winning: false,
            currentBetting: 0,
            currentRatio: 0
        },
        // currentPlayers: [],
        playerInfo: {},
        joined: [],
        countPlayers: 0,
        chat: [],
        tableHistory: [],
        // totalPlayers: 0,
        lag: false,
        tickTimer: null,
        lastHash: null,
        setting: {
            start: false,
            baseBet: 0,
            autoCashAt: 1.01,
            playTimes: null,
            playCount: 0
        },
        playSound: localData.playSound
    },
    mutations: {
        CHANGE_SOUND_STATUS: (state, data) => {
            state.playSound = data
            let localData = localStorage.getItem("AUTO_PLAY_SETTINGS")
            if (localData) {
                localData = JSON.parse(localData)
            } else {
                localData = {
                    betAmount: 1,
                    betRatio: 1.01,
                    playTimes: 100,
                    playSound: true
                }
            }
            localData.playSound = data
            localStorage.setItem("AUTO_PLAY_SETTINGS", JSON.stringify(localData))
            if (!data) {
                let waitingGame = document.getElementById('waitingGame');
                if (waitingGame) {
                    waitingGame.pause()
                    waitingGame.currentTime = 0
                }
                let startingGame = document.getElementById('startingGame');
                if (startingGame) {
                    startingGame.pause()
                    waitingGame.currentTime = 0
                }
            }
        },
        UPDATE_BALANCE: (state, data) => {
            state.currentPoint = parseInt(data)
        },
        UPDATE_WINNING_POINTS: (state, data) => {
            state.winningPoints = parseInt(data)
        },
        UPDATE_TOKEN_BALANCE: (state, data) => {
            state.currentTokens = parseFloat(data)
        },
        JOIN_GAME: (state, resp) => {
            state.currentPoint = parseInt(resp['total_points']);
            state.chat = resp['chat'];

            /** If username is a false value the user is not logged in */
            state.username = resp['username'];

            /** Variable to check if we are connected to the server */
            state.isConnected = true;
            state.game.gameState = resp['state'];
            state.playerInfo = resp['player_info'];

            // set current game properties
            state.game.gameId = resp['game_id'];
            state.game.maxWin = resp['max_win'];
            state.game.lastHash = resp['last_hash'];
            state.game.created = resp['created'];
            state.game.startTime = new Date(Date.now() - resp['elapsed']);
            state.joined = resp['joined'];
            state.countPlayers = state.joined.length
            state.tableHistory = resp['table_history'];

            if (state.game.gameState === 'IN_PROGRESS') {
                state.game.lastGameTick = Date.now();
                // Attach username to each user for sorting proposes
                for (let user in state.playerInfo) {
                    state.playerInfo[user].username = user;
                }
            }
            state.playerInfo = {...state.playerInfo}
        },
        GAME_STARTING: (state, info) => {
            if (state.playSound) {
                let waitingGame = document.getElementById('waitingGame');
                if (waitingGame) {
                    waitingGame.play()
                }
            }
            state.playerInfo = {}
            state.joined = [];
            state.countPlayers = state.joined.length
            state.game.winning = false;
            state.game.gameState = "STARTING";
            state.game.gameId = info['game_id'];
            state.game.timeTillStart = info['time_till_start'];

            state.game.startTime = new Date(Date.now() + info['time_till_start'])


            state.game.maxWin = info['max_win'];

            // Every time the game starts checks if there is a queue bet and send it
            if (state.game.nextBetAmount) {
                if (state.currentPoint > state.game.nextBetAmount) {
                    state.game.currentBetting = state.game.nextBetAmount
                    state.game.currentRatio = state.game.nextAutoCashout
                    doBet(state.game.nextBetAmount, state.game.nextAutoCashout
                        //     , function (err) {
                        //     if (err)
                        //         console.log('Response from placing a bet: ', err);
                        // }
                    );
                } else {
                    state.game.nextBetAmount = null
                }
            }

            if (state.setting.start) {
                // Check current balance
                if (state.currentPoint > state.setting.baseBet) {
                    // Check play times
                    if (state.setting.playTimes && state.setting.playCount < state.setting.playTimes) {
                        state.setting.playCount = state.setting.playCount + 1
                        state.game.currentBetting = state.setting.baseBet
                        state.game.currentRatio = state.setting.autoCashAt
                        doBet(state.setting.baseBet, state.setting.autoCashAt);
                    } else {
                        state.setting.start = false
                        state.setting.baseBet = 0
                        state.setting.autoCashAt = 0
                        state.setting = {...state.setting}
                    }
                } else {
                    state.setting.start = false
                    state.setting.baseBet = 0
                    state.setting.autoCashAt = 0
                    state.setting = {...state.setting}
                }
            }
            // self.trigger('game_starting', info);
        },
        GAME_STARTED: (state, bets) => {
            let waitingGame = document.getElementById('waitingGame');
            if (waitingGame) {
                waitingGame.pause()
                waitingGame.currentTime = 0
            }
            if (state.playSound) {
                let startingGame = document.getElementById('startingGame');
                if (startingGame) {
                    startingGame.play()
                }
            }

            state.joined = [];
            state.countPlayers = state.joined.length

            state.game.gameState = "IN_PROGRESS";
            state.game.startTime = Date.now()
            state.game.lastGameTick = state.game.startTime
            state.game.placingBet = false
            state.game.timeTillStart = null
            state.game.nextBetAmount = null
            state.game.nextAutoCashout = null

            //Create the player info object with bet and username
            //If you are in the bets rest your bet from your balance
            Object.keys(bets).forEach(function (username) {
                if (state.username === username) {
                    state.currentPoint -= bets[username];
                }
                state.playerInfo[username] = {bet: bets[username], username: username};
            });
            state.playerInfo = {...state.playerInfo}
        },
        GAME_TICK: (state, elapsed) => {
            if (state.playSound) {
                let startingGame = document.getElementById('startingGame');
                if (startingGame && startingGame.paused) {
                    startingGame.play()
                }
            }
            //
            /** Time of the last tick received */
            state.game.lastGameTick = Date.now();

            if (state.lag === true) {
                state.lag = false;
                // state.trigger('lag_change');
            }

            /** Correct the time of startTime every gameTick **/
            let currentLatencyStartTime = state.game.lastGameTick - elapsed;
            if (state.game.startTime > currentLatencyStartTime) {
                state.game.startTime = currentLatencyStartTime
            }

            if (state.tickTimer) {
                clearTimeout(state.tickTimer)
            }
            // state.tickTimer = setTimeout(self.checkForLag.bind(self), AppConstants.Engine.STOP_PREDICTING_LAPSE);

            // //Check for animation triggers
            // if(elapsed > AppConstants.Animations.NYAN_CAT_TRIGGER_MS && !self.nyan) {
            //     self.nyan = true;
            //     self.trigger('nyan_cat_animation');
            // }
        },
        GAME_CRASH: (state, data) => {
            let startingGame = document.getElementById('startingGame')
            if (startingGame) {
                startingGame.pause()
                startingGame.currentTime = 0
            }


            if (state.tickTimer) {
                clearTimeout(state.tickTimer)
            }

            //If the game crashed at zero x remove bonuses projections by setting them to zero.
            if (data.game_crash === 0) {
                // self.setBonusesToZero();
            }

            //Update your balance if you won a bonus, use this one because its the bonus rounded by the server
            for (let user in data.bonuses) {
                // console.assert(self.playerInfo[user]);
                state.playerInfo[user].bonus = data.bonuses[user]; //TODO: Deprecate sending bonuses to the client?
                if (state.username === user) {
                    // ADD WINNING POINTS
                    // state.currentPoint += data.bonuses[user];
                    state.winningPoints += data.bonuses[user];
                }
            }
            state.playerInfo = {...state.playerInfo}

            state.lastHash = data.hash

            let gameInfo = {
                created: state.game.created,
                ended: true,
                game_crash: data.game_crash,
                game_id: state.game.gameId,
                hash: data.hash,
                player_info: state.playerInfo
            };

            // Add the current game info to the game history and if the game history is larger than 40 remove one element
            if (state.tableHistory.length >= 40) {
                state.tableHistory.pop()
            }
            // console.log(gameInfo)
            state.tableHistory = [gameInfo, ...state.tableHistory]

            //Clear current game properties
            state.game.gameState = 'ENDED';
            state.game.cashingOut = false;
            state.lag = false;

            //Clear Animation trigger flags
            state.nyan = false;

            // self.trigger('game_crash', data);
        },
        PLAYER_BET: (state, data) => {
            if (state.username === data.username) {
                state.game.placingBet = false;
                state.game.nextBetAmount = null;
                state.game.nextAutoCashout = null;
            }
            state.joined.splice(data.index, 0, data.username);
            state.countPlayers = state.joined.length
        },
        DISCONNECT: (state, data) => {
            state.isConnected = false;
            state.game.gameState = "STARTING";
            console.log('Client disconnected |', data, '|', typeof data);
        },
        BET_DATA: (state, data) => {
            if (!state.game.placingBet) {
                state.game.nextBetAmount = data['amount'];
                state.game.nextAutoCashout = data['autoCashOut'];
                state.game.placingBet = true;
            }
        },
        CURRENT_BET_DATA: (state, data) => {
            state.game.nextBetAmount = data['amount'];
            state.game.nextAutoCashout = data['autoCashOut'];
        },
        CANCEL_BET: (state) => {
            if (!state.game.nextAutoCashout) {
                return console.error('Can not cancel next bet, wasn\'t going to make it...')
            }
            state.game.nextBetAmount = null;
            state.game.placingBet = false;
        },
        AUTO_PLAY: (state, data) => {
            state.setting.start = true
            state.setting.autoCashAt = data['autoCashAt']
            state.setting.baseBet = data['baseBet']
            state.setting.playCount = 0
            let settings = localStorage.getItem("AUTO_PLAY_SETTINGS")
            if (settings) {
                settings = JSON.parse(settings)
                state.setting.playTimes = settings['playTimes']
            } else {
                state.setting.playTimes = null
            }
        },
        UPDATE_AUTO_PLAY_VALUE: (state, data) => {
            state.setting.autoCashAt = data['autoCashAt']
            state.setting.baseBet = data['baseBet']
        },
        STOP_AUTO: (state) => {
            state.setting.start = false
            state.setting.autoCashAt = 0
            state.setting.baseBet = 0
            state.setting.playTimes = null
            state.setting.playCount = 0
        },
        CASHED_OUT: (state, resp) => {
            //Add the cashout percentage of each user at cash out
            if (!state.playerInfo[resp['username']]) {
                return console.warn('Username not found in playerInfo at cashed_out: ', resp['username']);
            }

            state.playerInfo[resp['username']].stopped_at = resp.stopped_at;

            if (state.username === resp.username) {
                state.game.cashingOut = false;
                state.game.winning = true;
                // state.currentPoint = state.currentPoint + state.playerInfo[resp.username].bet * resp.stopped_at / 100;
                // ADD TO WINNING POINTS
                state.winningPoints = state.winningPoints + state.playerInfo[resp.username].bet * resp.stopped_at / 100;
                // state.winningPoints = state.winningPoints + (state.playerInfo[resp.username].bet * resp.stopped_at / 100) - state.playerInfo[resp.username].bet;
                // state.currentPoint = state.currentPoint + state.playerInfo[resp.username].bet;
            }
            state.playerInfo = {...state.playerInfo}
            // self.calcBonuses();
            //
            // self.trigger('cashed_out', resp);
        },
        RELOAD_HISTORY: (state) => {
            // console.log("do reload history")
            state.tableHistory = [...state.tableHistory]
            // console.log(state.tableHistory[0].game_crash)
        }
    },
    actions: {
        bet({commit, state}, data) {
            commit("BET_DATA", data)
            if (state.game.gameState === 'STARTING') {
                console.log("do bet")
                commit("CURRENT_BET_DATA", data)
                return doBet(data['amount'], data['autoCashOut']);
            }
        },
        reloadHistory({commit}) {
            commit("RELOAD_HISTORY")
        }
    },
    getters: {
        gameIsConnected: state => state.isConnected,
        gameData: state => state.game,
        gameSettings: state => state.setting,
        currentPoint: state => state.currentPoint,
        winningPoints: state => state.winningPoints,
        currentTokens: state => state.currentTokens,
        tableHistory: state => state.tableHistory,
        playerInfo: state => state.playerInfo,
        gameUsername: state => state.username,
        playerJoined: state => state.joined,
        countPlayers: state => state.countPlayers,
        gamePlaySound: state => state.playSound
    }
}

function doBet(amount, autoCashOut) {
    window.getApp.$emit("placing_bet", amount, autoCashOut);
}

export default gameData;
