import Vue from "vue";
import VueI18n from "vue-i18n";
import enLocale from "./en.json";
import esLocale from "./es.json";
import frLocale from "./fr.json";
import jaLocale from "./ja.json";
import koLocale from "./ko.json";
import zhLocale from "./zh_cn.json";

Vue.use(VueI18n);

const messages = {
    en: {
        ...enLocale
    },
    es: {
        ...esLocale
    },
    fr: {
        ...frLocale
    },
    ja: {
        ...jaLocale
    },
    ko: {
        ...koLocale
    },
    zh_cn: {
        ...zhLocale
    },
};
const supportLanguages = ['en', 'es', 'fr', 'ko', 'zh', 'zh_cn', 'ja']
const storageLang = localStorage.getItem("language")
let local = navigator.language.split('-')[0]
if (!supportLanguages.includes(local))
    local = 'ja'

const i18n = new VueI18n({
    // set locale
    // options: en | ja
    //locale: localStorage.getItem("language") || "en",
    locale: storageLang || local,
    // set locale messages
    messages

});

export default i18n;
