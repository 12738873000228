import request from "@/plugins/axiosRequest";
const url = "/v1/consultations";

export function sendConsultations(data) {
  return request({
    url: url,
    method: "post",
    data
  });
}

export default { sendConsultations };
