<template>
  <nav class="
      navbar navbar-light navbar-expand
      text-white
      shadow
      topbar
      static-top
    " :class="{ toggled: $store.state.collapseNav }">
    <div class="container-fluid">
     <div class="d-flex justify-content-between w-100">
      <button title="menu" class="btn btn-link d-md-none rounded-circle me-3" @click="handleNAvStatus" id="sidebarToggleTop" type="button">
        <i class="fas fa-bars"></i>
      </button>
      <div class="navbar-brand d-flex justify-content-center align-items-center sidebar-brand m-0 d-block d-md-none">
        <div class="sidebar-brand-icon">
          <div><img src="@/assets/img/2_tmra_0001_03_logo_y1x4_w.svg" style="width: 30px;"></div>
        </div>
        
        <div class="sidebar-brand-text mx-3	">
          <div><img class="w-100" src="@/assets/img/2_tmra_0001_04_logo_y1x4_w.svg" width="130" height="14"></div>
        </div>
      </div>
      <ul class="navbar-nav ms-md-auto">
        <div class="d-none d-sm-block topbar-divider"></div>

        <li class="nav-item dropdown no-arrow">
          <div class="nav-item dropdown no-arrow">

            <a class="nav-link" aria-expanded="false" data-bs-toggle="dropdown" href="#"><span
                class="d-none d-lg-inline me-2 small fw-bold z_tmrc_cft031">{{ userName }}</span>
              <img v-if="currentUser.avatar_url"  :alt="userName" width="32"
                :src="currentUser.avatar_url" class="border rounded-circle img-profile" />
              <span v-else>{{ userName | firstCharacter(true) }}</span>
            </a>
            <div class="dropdown-menu shadow dropdown-menu-end animated--grow-in">

              <a class="dropdown-item py-3" href="#" style="color: var(--bs-success);">
                <i
                  class="fa fa-volume-down fa-sm fa-fw me-2 text-gray-400"></i>&nbsp;{{$t('label_sound')}}<div>
                  <form>
                    <!-- Start: form_sound -->
                    <div class="d-flex align-items-xxl-center">
                      <div class="d-flex align-items-center pretty p-switch p-fill px-3 pt-1">
                        <span class="z_tmrc_cft031 pe-2">OFF<br></span>
                        <input type="checkbox" required="" v-model="gamePlaySound" @change="changeSoundStatus">
                        <div class="state p-info"><label class="form-label text-white"></label></div>
                        <span class="z_tmrc_cft001 z_tmrc_cft031 ps-4">ON<br></span>
                      </div>
                    </div><!-- End: form_sound -->
                  </form>
                </div></a>

              <router-link to="/settings" tag="a" class="dropdown-item">
                <i class="fa-solid fa-gear fa-sm"></i>
                {{ $t("headerMenu.setting") }}
              </router-link>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="logOut">
                <i class="fa-solid fa-sign-out-alt fa-sm"></i>
                {{ $t("headerMenu.logout") }}</a>
            </div>
          </div>
        </li>
      </ul>
     </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentUser", "gamePlaySound"]),
    userName() {
      if (this.currentUser.id) {
        if (!this.currentUser.name && !this.currentUser.last_name) {
          return this.currentUser.email;
        } else {
          return (this.currentUser.name || "") + " " + (this.currentUser.last_name || "");
        }
      }
      return "Player";
    },
    copyright() {
      return this.$t("footer.copyright", { year: new Date().getFullYear() });
    },
  },
  filters: {
    firstCharacter: function (value, onlyFirstCharacter) {
      if (!value) {
        return '';
      }

      value = value.toString();

      if (onlyFirstCharacter) {
        return value.charAt(0).toUpperCase();
      } else {
        return value.toUpperCase();
      }
    }
  },
  methods: {
    logOut() {
      this.$store.dispatch("logout")
      this.$store.state.collapseNav = false;
      window.getApp.$emit("APP_LOGOUT");
    },
    handleNAvStatus(){
      this.$store.state.collapseNav = !this.$store.state.collapseNav;
    },
    changeSoundStatus(event) {
      this.$store.commit('CHANGE_SOUND_STATUS', event.target.checked)
    }
  }
};
</script>
<style lang="scss">
.topbar {
  height: 50px;
  background: linear-gradient(#707ab8, #1e2454 60%);
  position: fixed !important;
  left: 6.5rem;
  right: 0;
  z-index: 1;
  &.toggled {
      left: 0 ;
  }
  .dropdown-menu {
    background-color: #3c4179;
    border-color: #07e1eb;
  }

  .dropdown-item {
    color: #07e1eb;

    i {
      color: #ffffff;
    }

    &:hover,
    &:focus {
      color: #07e1eb;
      background-color: #1e2454;
    }
  }

  .dropdown-divider {
    border: 1px solid #07e1eb;
  }
}
@media (min-width: 768px){
  .topbar {
    left: 14rem ;
    &.toggled {
      left: 0 ;
  }
}
}

</style>