import request from "@/plugins/axiosRequest";

const url = "/v1/referrals";

export function getReferrals(queries) {
    return request({
        url: url,
        method: "get",
        params: queries
    });
}

export function getReferralsOverview() {
    return request({
        url: `${url}/overview`,
        method: "get"
    });
}

export function getAffiliateHistories(queries) {
    return request({
        url: `${url}/affiliate-histories`,
        method: "get",
        params: queries
    });
}

export default {getReferrals, getReferralsOverview, getAffiliateHistories};
