import request from "@/plugins/axiosRequest";

const url = "/v1/system_settings";

export function fetchList(query) {
    return request({
        url: url,
        method: "get",
        params: query
    });
}

export default {fetchList};
