<template>
  <div id="app" class="body h-100">
    <div id="wrapper" class="d-flex z_tmrc_cbg021">
      <nav-menu v-if="
        !fullPage
      "></nav-menu>
      <div id="content-wrapper"  class="flex-grow-1" style="overflow-x: hidden">
       <div id="content" :class="{'content-sticky': !fullPage ,'toggled-body' : !$store.state.collapseNav }" >
          <header-component v-if="
            !fullPage
          "></header-component>
        
          <div :style="[!fullPage ? { 'padding-top': '3rem'} : {}]">
            <router-view></router-view>
          </div>
          

        </div>
       <main-footer ></main-footer>
      </div>
    </div>
    
    
    <a class="border rounded d-inline scroll-to-top" href="#app"><i class="fas fa-angle-up"></i></a>
  </div>
</template>

<script>
import NavMenu from "@/components/nav-menu/nav-menu";
import AppEvents from "@/event";
import HeaderComponent from "@/components/header";
import MainFooter from "@/components/main-footer";

export default {
  name: "App",
  data() {
    return {
      dialogTableVisible: false,
    };
  },
  components: {
    NavMenu,
    MainFooter,
    HeaderComponent
  },
  created() {
    let scrollToTop = document.querySelector('.scroll-to-top');

    if (scrollToTop) {

      // Scroll to top button appear
      window.addEventListener('scroll', function () {
        let scrollDistance = window.pageYOffset;

        //check if user is scrolling up
        if (scrollDistance > 100) {
          scrollToTop.style.display = 'block';
        } else {
          scrollToTop.style.display = 'none';
        }
      });
    }

    AppEvents.forEach((item) => {
      this.$on(item.name, item.callback);
    });

    this.$on("placing_bet", this.placing_bet);

    window.getApp = this;
  },
  computed: {
    fullPage() {
      if (!this.$route) {
        return true
      }
      return ['/login', '/sign-up', '/forgot-password', '/reset-password'].includes(this.$route.path)
    }
  },
  methods: {
    placing_bet(amount, autoCashOut) {
      this.$socket.emit("place_bet", amount, autoCashOut, (error, resp) => {
        if (error) {
          console.warn("place_bet error: ", error);

          if (error !== "GAME_IN_PROGRESS" && error !== "ALREADY_PLACED_BET") {
            alert("There was an error, please reload the window: " + error);
          }
          // if (callback)
          //   callback(error);
          // return;
        }

        // this.$store.commit("JOIN_GAME", resp)
        // console.error('joining the game...');
        console.log(resp);
      });
    },
  },
};
</script>
<style lang="scss">
.content-sticky{
 
  &.toggled-body{
    margin-left: 6.5rem;
  }
}
@media (min-width: 768px) {
.content-sticky{
  margin-left: 6.5rem;
  &.toggled-body{
    margin-left: 14rem;
  }
}
}
</style>
