<template>
  <footer id="main-footer">
    <div class="footer-container content-wrap">
      <p>
        {{ copyright }}
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  computed: {
    copyright() {
      return this.$t('footer.copyright', { year: new Date().getFullYear() })
    }
  }
};
</script>
<style lang="scss">
#main-footer {
  padding: 2rem;
  font-weight: bold;
  background-color: #3c4179;
  color: #07e1eb;
  font-size: 0.8rem;
}
</style>
