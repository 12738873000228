import {chatHistories} from "@/api/chatMessages";

const chatEngine = {
    state: {
        history: [],
        onlineUsers: 0
    },
    mutations: {
        SET_CHAT_HISTORIES: (state, data) => {
            state.history = data
        },
        NEW_MESSAGE: (state, data) => {
            // state.history.push(data)
            state.history.unshift(data)
        },
        SET_ONLINE_USERS: (state, data) => {
            state.onlineUsers = data
        }
    },

    actions: {
        getChatHistories({commit}) {
            return new Promise((resolve, reject) => {
                chatHistories()
                    .then(response => {
                        const data = response.data;
                        commit("SET_CHAT_HISTORIES", data);
                        resolve(data);
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        }
    },
    getters: {
        chatHistory: state => state.history,
        onlineUsers: state => state.onlineUsers
    }
}
export default chatEngine;
