import Vue from 'vue'
import App from './App.vue'
import store from "./store";

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import moment from 'moment';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import VueMq from 'vue-mq'

import vueNumeralFilterInstaller from 'vue-numeral-filter';

import VeeValidate from 'vee-validate';

import i18n from "./lang";

import '@/scss/_global.scss';

import router from './router'
import ActionCableVue from 'actioncable-vue';

import VueSocketIO from 'vue-socket.io'
import VueMoment from "vue-moment";
import "./filters/";

import VueClipboard from 'vue-clipboard2'

import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';

Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.use(VueMoment);
Vue.use(moment)
Vue.use(ElementUI, {locale});
Vue.use(VueMq, {
    breakpoints: {
        md: 768,
        lg: Infinity,
    }
})
Vue.use(vueNumeralFilterInstaller, {locale: 'en-gb'});
Vue.use(VeeValidate);

VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.use(ActionCableVue, {
    debug: true,
    debugLevel: 'error',
    connectionUrl: process.env.VUE_APP_CHAT_URL,
    connectImmediately: false,
});

Vue.use(new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_SOCKET_URL,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    }
}))

new Vue({
    i18n,
    render: h => h(App),
    router,
    store,
    data() {
        return {
            isDisconnected: false
        }
    },
    channels: {
        EnglishRoomChannel: {
            connected() {
                // console.log('I am connected.');
            },
            rejected() {
            },
            received(data) {
                if (data["user_online"] !== undefined) {
                    this.$store.commit("SET_ONLINE_USERS", data["user_online"])
                } else {
                    this.$store.commit("NEW_MESSAGE", data)
                }
            },
            disconnected() {
            }
        }
    },
    sockets: {
        connect() {
            // Fired when the socket connects.
            this.$store.dispatch("requestOtt").then(data => {
                this.$socket.emit('join', {ott: data}, (err, resp) => {
                    if (err) {
                        console.error('Error when joining the game...', err);
                        return
                    }
                    this.$store.commit("JOIN_GAME", resp)
                    console.log('joining the game...');
                    // console.log(resp)
                })
            }).catch(() => {
                this.$socket.emit('join', {ott: null}, (err, resp) => {
                    if (err) {
                        console.error('Error when joining the game...', err);
                        return
                    }
                    this.$store.commit("JOIN_GAME", resp)
                    // console.error('joining the game...');
                    // console.log(resp)
                })
            })

            if (this.isDisconnected) {
                return
            }
            this.sockets.subscribe("game_started", bets => {
                this.$store.commit("GAME_STARTED", bets)
            })

            this.sockets.subscribe("game_starting", info => {
                // console.log("play game")
                this.$store.commit("GAME_STARTING", info)
            })

            this.sockets.subscribe("game_tick", elapsed => {
                this.$store.commit("GAME_TICK", elapsed)
            })

            this.sockets.subscribe("game_crash", data => {
                this.$store.commit("GAME_CRASH", data)
            })

            this.sockets.subscribe("cashed_out", data => {
                this.$store.commit("CASHED_OUT", data)
                // console.log("cashed_out")
                // console.log(data)
            })

            this.sockets.subscribe("player_bet", data => {
                this.$store.commit("PLAYER_BET", data)
                // console.log("player_bet")
                // console.log(data)
            })
        },

        disconnect(data) {
            this.$store.commit("DISCONNECT", data)
            this.isDisconnected = true
        },

        // Fired when the server sends something on the "messageChannel" channel.
        messageChannel(data) {
            // console.log(data)
            this.socketMessage = data
        }
    },
    mounted() {
        this.$cable.subscribe({
            channel: 'EnglishRoomChannel'
        });
        this.$store.dispatch("getChatHistories")
    }
}).$mount('#app')
