import request from "@/plugins/axiosRequest";

const url = "/v1/exchange_rates";
const coinPriceUrl = process.env.VUE_APP_PAYMENT_URL + "/api/v1/rates/";

export function getExchangeRates() {
    return request({
        url: url,
        method: "get"
    });
}

export function coinExchangeRates(coin, price) {
    coin = coin.toUpperCase()
    price = price.toUpperCase()
    return request({
        url: coinPriceUrl + `${coin}/${price}`,
        method: "get"
    });
}

export default {getExchangeRates, coinExchangeRates};
