import request from "@/plugins/axiosRequest";

const sign_up_url = "/user_auth";
const sign_in_url = "/user_auth/sign_in";
const sign_out_url = "/user_auth/sign_out";
const validate_token_url = "/user_auth/validate_token";
const update_password_url = "/user_auth/password";
const update_profile_url = "/user_auth";
const ott_url = "/v1/ott";
const userProfileUrl = "/v1/users";

// email, password, password_confirmation, confirm_success_url

export function registerByEmail(
    profile
) {
    const data = {
        email: profile['email'].trim(),
        name: profile['name'],
        last_name: profile['lastName'],
        username: profile['username'],
        phone_number: profile['phoneNumber'],
        referral_code: profile['referralCode'],
        password: profile['password'],
        password_confirmation: profile['passwordConfirmation'],
        confirm_success_url: window.location.origin + "/login"
    };
    return request({
        url: sign_up_url,
        method: "POST",
        data
    });
}

export function loginByEmail(email, password) {
    const data = {
        email,
        password
    };
    return request({
        url: sign_in_url,
        method: "post",
        data
    });
}

export function logout() {
    return request({
        url: sign_out_url,
        method: "DELETE"
    });
}

export function validateToken() {
    return request({
        url: validate_token_url,
        method: "GET"
    });
}

export function updatePassword(
    current_password,
    password,
    password_confirmation
) {
    const data = {
        current_password: current_password,
        password: password,
        password_confirmation: password_confirmation
    };
    return request({
        url: update_password_url,
        method: "PUT",
        data
    });
}

export function updateProfile(data) {
    return request({
        url: update_profile_url,
        method: "PUT",
        data
    });
}

export function updateAvatar(avatar) {
    const form = new FormData()
    form.append('avatar', avatar)
    return request({
        url: `${userProfileUrl}/avatar`,
        method: 'put',
        data: form
    })
}

export function checkCurrentBalance() {
    return request({
        url: `${userProfileUrl}/balance`,
        method: 'get',
    })
}

export function convertWinningToPlayPoints(data) {
    return request({
        url: `${userProfileUrl}/convert_winning_point`,
        method: 'post',
        data: data
    })
}

export function transferWinningPoints(data) {
    return request({
        url: `${userProfileUrl}/transfer_winning_points`,
        method: 'post',
        data: data
    })
}

export function transferPlayPoints(data) {
    return request({
        url: `${userProfileUrl}/transfer_play_points`,
        method: 'post',
        data: data
    })
}

export function requestOtt() {
    return request({
        url: ott_url,
        method: "POST"
    });
}

export function sendResetPassword(email, redirectUrl) {
    const data = {
        email,
        redirect_url: redirectUrl
    };
    return request({
        url: update_password_url,
        method: "POST",
        data
    });
}

export function resetPassword({token, password, passwordConfirmation}) {
    const data = {
        reset_password_token: token,
        password: password,
        password_confirmation: passwordConfirmation
    };
    return request({
        url: update_password_url,
        method: "PUT",
        data
    });
}
