import {getReferrals, getReferralsOverview} from "@/api/referrals"

const referralsData = {
    state: {
        referral: {
            overview: {
                affiliate_type: "normal",
                affiliate_code: "",
                affiliate_fee: 0,
                paid_affiliate_fee: 0,
            },
            summary: []
        }
    },
    mutations: {
        SET_REFERRAL_OVERVIEW: (state, data) => {
            state.referral.overview = data;
        },
        SET_REFERRAL_SUMMARY: (state, data) => {
            state.referral.summary = data;
        }
    },
    actions: {
        getReferralsOverview({commit}) {
            return new Promise((resolve, reject) => {
                getReferralsOverview()
                    .then(response => {
                        const data = response.data;
                        commit("SET_REFERRAL_OVERVIEW", data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
        getReferralsSummary({commit}) {
            return new Promise((resolve, reject) => {
                getReferrals()
                    .then(response => {
                        const data = response.data;
                        commit("SET_REFERRAL_SUMMARY", data);
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            });
        },
    },
    getters: {
        referralsData: state => state.referral
    }
}

export default referralsData;
